import React, {useState, useEffect} from "react";
import ReactPlayer from "react-player";
import {
    BrowserRouter as Router,
    Route,
    Routes,
   
  } from "react-router-dom";
  import Optimizar from "./inicio/inicio";

import BackDiseño from "./lensun/backDiseño/backDiseño";
import Videos from "./lensun/videos/videos";
import Cabeza from "./lensun/header/header";
import Pie from "./lensun/pie/pie";

import Lensun from "../src/lensun/lensun principal/principla/lensun";
import Maquinas from "./lensun/lensun principal/maquinas/maquinas";

import Glitter from "./lensun/lensun principal/gliter/glitter";

import Cargando from "./inicio/loading/loading";
import Mark from "./mark/mark";
import Maxxa from "./maxxa/maxxa"
import Wuw from "./wuw/wuw";
import videoLogin from "./inicio/foto/videos/videoCargando.mp4"
import MaxxaPrincipal from "./maxxa/maxxaprin";
import BateriasMaxxa from "./maxxa/baterias";










function App  () { 

    return(
   
        <Router>
        <Cabeza />
        <Routes>
        <Route path="/" element={<Optimizar />} />
        <Route path="/lensun" element= {<Lensun />} />
        <Route path="/glitter" element= {<Glitter />} />
        <Route path="/maquinas" element={<Maquinas />} />   
        <Route path="/videos" element= {<Videos />} />
        <Route path= "/lensun" element= {<Lensun />} />
        <Route path="/back" element={<BackDiseño />} />
        <Route path="/mark" element={<Mark />} />
        <Route path="/maxxaaccesorios" element={<Maxxa />} />
        <Route path="/wuw" element={<Wuw />} />
        <Route path="/maxxa" element={<MaxxaPrincipal />} />
        <Route path="/bateriasmaxxa" element={<BateriasMaxxa />} />
        </Routes>

        <Pie />
        </Router>
    )
  
    }
  
  

export default App