import "./maxxa.css"
import productos from "../lensun/lensun principal/principla/len"

function MaxxaPrincipal(){
    return(
        <>
        <div></div>
         <body className="maxxa">
    
    <section className="fotosMaxxa">
       
    <a href="/maxxa"><img className="maxxaTop" src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/logos%20marcar%2Flogomaxxa3.png?alt=media&token=1100e852-4a7e-4e92-9cf8-578dd6b27ff6" /></a>
      <br></br>
      <div class="container px-4 px-lg-5 mt-5">
            <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                <div class="col mb-5">
                  <div className="backMaxxa"> 
                    <div class="card h-100">
                       <a href="/bateriasmaxxa">  <img class="card-img-top" src={productos[16].img} /></a> 
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">BATERIAS</h5>
                           
                           </div>
                        </div>
                        </div>  
                        
                    </div>
                </div>
                <div class="col mb-5">
                  <div className="backMaxxa"> 
                    <div class="card h-100">
                       <a href="/maxxaaccesorios"><img class="card-img-top" src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fauriculares%2Fm45%20negro.jpg?alt=media&token=e90e1ac2-af76-4681-ac1e-56ffd9120d35" /> </a>  
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">ACCESORIOS</h5>
                           
                           </div>
                        </div>
                    </div>    
                </div>
                </div>
            </div>
        </div>
        </section>
        </body>


      
        </>
    )
}

export default MaxxaPrincipal