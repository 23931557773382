
import "./maxxa.css"


function BateriasMaxxa(){
    return(
        <>
        <body className="maxxa">
    <section className="fotosMaxxa">
   <a href="/maxxa"> <img className="maxxaTop" src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/logos%20marcar%2Flogomaxxa3.png?alt=media&token=1100e852-4a7e-4e92-9cf8-578dd6b27ff6" /></a>
      <br></br>
      <div class="container px-4 px-lg-5 mt-5">
            <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                <div class="col mb-5">
                  <div className="backMaxxa"> 
                    <div class="card h-100">
                        <img className="fotoProd"  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%205S.webp?alt=media&token=23ce1720-7d63-4f85-8618-f715fc8a12c2" /> 
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 5g</h5>
                            <p> Capacidad 144mAh</p>
                           </div>
                        </div>
                        </div>  
                        
                    </div>
                </div>
                <div class="col mb-5">
                  <div className="backMaxxa"> 
                    <div class="card h-100">
                        <img className="fotoProd" src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%205S.webp?alt=media&token=23ce1720-7d63-4f85-8618-f715fc8a12c2" /> 
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 5s</h5>
                           <p>Capacidad 1500mAh</p>
                           </div>
                        </div>
                        </div>  
                        
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%206G.webp?alt=media&token=d0170416-6e5e-4745-aeeb-a083c418ffb9" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 6g</h5>
                            <p>Capacidad 1800mAh</p>
                           </div>
                        </div>
                       </div>
                       
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%206%2B.webp?alt=media&token=670dacdd-0564-449e-a6b0-6791bac6e8d2" alt="imagen de back" />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 6 Plus</h5>
                            <p>Capacidad 2915mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%206S%2B.webp?alt=media&token=cd309fb7-7b22-4948-bb3b-54724401a09a" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 6s+</h5>
                            <p>Capacidad 1715 mAh</p>
                           </div>
                        </div>
                       </div>
                        
                    </div>
                </div><div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%20SE.webp?alt=media&token=3e0bc784-0ac6-4e4a-bc05-17faadcac52c" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone Se</h5>
                            <p>Capacidad 1624 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%207%2B.webp?alt=media&token=d356ff6b-f21c-4a03-9ac2-c4247555cb37" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 7 Plus</h5>
                            <p>Capacidad 2900 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%208G.webp?alt=media&token=719a2ec9-794c-4928-8c76-94b4b92089ce" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 8</h5>
                            <p>Capacidad 1821 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%20X.webp?alt=media&token=ac763a16-5fec-4f76-a58f-02886677e155" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone X</h5>
                            <p>Capacidad 2750 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%20SE2020.webp?alt=media&token=16848837-038e-48b7-b18d-410575184d82" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone Se2020</h5>
                            <p>Capacidad 1821 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%20XR.webp?alt=media&token=1c1bf113-7009-4dcf-bc10-e4201e3ea74d" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone XR</h5>
                            <p>Capacidad 2942 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%20XR.webp?alt=media&token=1c1bf113-7009-4dcf-bc10-e4201e3ea74d" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone Xs</h5>
                            <p>Capacidad 2658 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%20XS%20MAS.webp?alt=media&token=fdbb7f40-4fa1-418b-8d14-0ae966162dad" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone Xsmax</h5>
                            <p>Capacidad 3174 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>
                <div class="col mb-5">
                <div className="backMaxxa">
                    <div class="card h-100">
                        <img  src="https://firebasestorage.googleapis.com/v0/b/lensuncaba.appspot.com/o/maxxa%2Fbaterias%2FIPH%2011.webp?alt=media&token=8c6bcf3f-eeab-4dc1-a1aa-45fb3cd658b5" alt="..." />
                        <div class="card-body p-4">
                          <div class="text-center">
                            <h5 class="fw-bolder">Bateria Compatible con Iphone 11</h5>
                            <p>Capacidad 2716 mAh</p>
                           </div>
                        </div>
                       
                        </div>
                    </div>
                </div>


                </div>
                </div>      
    </section>
 </body>   
        </>
    )
}

export default BateriasMaxxa