import './pie.css'
import productos from '../lensun principal/principla/len'
import Piecorreo from './piecorreo'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label} from 'reactstrap';

function Pie(){
    return(
        <div className='body'>
            <hr className='hr'></hr>
          <h5 className='cont'>CONTACTO</h5>
          <div className='items'> 
            <a  href='https://api.whatsapp.com/send?phone=5491134007900&text=.' target="_blank"><img className='pieF' src={productos[6].img}></img> <p className='parr'>telefono </p></a>
            <a className='botonPie'><img className='pieF2' src={productos[9].img} /><div className='parr'></div><div className='corr'><Piecorreo/> </div>  </a>
            <a href="https://www.instagram.com/optimizartecno/?igshid=YmMyMTA2M2Y%3D"  target="_blank"><img className='pieF' src={productos[10].img}></img><p className='parr'>instagram</p> </a>
            </div>

        
        
            
        </div>
    )
}

export default Pie